import { useRef, useState, useEffect } from 'react';
import { subDays, subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import Swal from 'sweetalert2';
import useAuth from '../../hooks/useAuth'

const now = new Date();




const notifications = window.sessionStorage.getItem("solicitud_pendiente")
const username = window.sessionStorage.getItem("username")

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
const [ solicitud, setSolicitud ] = useState(false);

const abrir = () => {
  setSolicitud(true);
};

const cerrar = () => {
  setSolicitud(false);
};


  const modalSolicitud = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, aceptar!'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          '¡Aceptado!',
          'La solicitud ha sido aceptada.',
          'success'
        )
      }
    })
  }




  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
        {notifications === "1" ? 
          <Badge
            color="error"
            badgeContent={" "}
          >
            <BellIcon fontSize="small" />
          </Badge>
           : <Badge
           badgeContent={" "}
         >
           <BellIcon fontSize="small" />
         </Badge>}
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notificaciones
          </Typography>
        </Box>
        {notifications === "0"
          ? (
            <>
            <Box sx={{ p: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                No tiene notificaciones pendientes
              </Typography>
            </Box>
            </>
          )
          : (
            <>
              <List disablePadding>
                    <ListItem
                      divider
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText'
                          }}
                        >
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={(
                          <Link
                            color="textPrimary"
                            sx={{ cursor: 'pointer' }}
                            underline="none"
                            variant="subtitle2"
                            href="/dashboard/accept-token"
                          >
                            Tiene una solicitud de Tokens pendiente
                          </Link>
                        )}
                      />
                    </ListItem>
              </List>
            </>
          )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1
                }}
              >
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                >
                  Marcar como leída
                </Button>
              </Box>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
