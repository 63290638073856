import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const username = window.sessionStorage.getItem('username');

const Session = () => {
  const { logout } = useAuth();

  useEffect(() => {
    if (username==='undefined') {
      logout();
    }
  }
    , []);

};


export default Session;
