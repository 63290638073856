import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const GraphicCircle = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
    <g id="master_icon-left_medium" data-name="master/icon-left/medium" transform="translate(2 -2)">
      <g id="solid_chart-pie" data-name="solid/chart-pie" transform="translate(-4)">
        <path id="Vector" d="M0,8A8,8,0,0,1,8,0V8h8A8,8,0,1,1,0,8Z" transform="translate(2 2)" fill="rgba(#919EAB)"/>
        <path id="Vector-2" data-name="Vector" d="M0,0A8.014,8.014,0,0,1,3.657,2.091,8.014,8.014,0,0,1,5.748,5.748H0Z" transform="translate(12 2.252)" fill="rgba(#919EAB)"/>
      </g>
    </g>
  </svg>
  , 'GraphicCircle'
);

export default GraphicCircle;