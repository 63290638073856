import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const FillSquare = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
    <g id="master_icon-left_medium" data-name="master/icon-left/medium" transform="translate(1 -3)">
      <g id="solid_chart-square-bar" data-name="solid/chart-square-bar" transform="translate(-4)">
        <path id="Vector" d="M2,0A2,2,0,0,0,0,2V12a2,2,0,0,0,2,2H12a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2Zm9,4A1,1,0,0,0,9,4v6a1,1,0,0,0,2,0ZM8,6A1,1,0,0,0,6,6v4a1,1,0,0,0,2,0ZM5,9A1,1,0,0,0,3,9v1a1,1,0,0,0,2,0Z" transform="translate(3 3)" fill="#919EAB"/>
      </g>
    </g>
  </svg>
  , 'FillSquare'
);

export default FillSquare;