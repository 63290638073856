import Swal from 'sweetalert2';
const username = window.sessionStorage.getItem('username');

const logout = () =>{
    if (username==='undefined') {
        Swal.fire({
          title: 'Su sesión ha expirado',
          text: 'Debe volver a inciar sesión para continuar',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'success',
          confirmButtonText: 'Ok',
        }).then(() => {
          localStorage.clear()
          }).then(
          () => {
            window.location.reload();
          }
        );
      }
      else{
        console.log('sigue logueado')
      }
}

export default logout;