import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../__Api__/authApi';
import Swal from 'sweetalert2'
import wait from '../utils/wait'



const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;
    
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = (props) => {

  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const jwt = window.sessionStorage.getItem('jwt');
        const user_id = window.sessionStorage.getItem('user_id');

        if (jwt && user_id) {
          const user = await authApi.me(user_id);

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  



  const login = async (email, password) => {
    
    const { jwt, user_id }= await authApi.login({ email, password });
    
    const user = await authApi.me(user_id, jwt);
    const aprobado = window.sessionStorage.getItem('approved');

     if(user_id === undefined){
       dispatch({
         isAuthenticated: false,
          user: null
        });
     }
    
      if ( user_id !== undefined) {
        sessionStorage.setItem('jwt', jwt);
        sessionStorage.setItem('user_id', user_id);

    
        dispatch({
          type: 'LOGIN',
          payload: {
            user
          }
        })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Usuario o Contraseña incorrectos',
            footer: '(chequee que su usuario haya sido verificado)',
          })
        }

  };
  

  const logout = async () => {
    sessionStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (username, email, name, last_name, password) => {
    const user_id  = await authApi.register({ username, email, name, last_name, password });
    const user = authApi.me(user_id);
     if (!user_id) {
      Swal.fire({
        icon: 'success',
        title: 'Exito!',
        text: 'Registro exitoso! Comprueba su casilla de mail para confirmar su cuenta'
      }).then(function() {
        window.location = "/";
    } );
     } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'El nombre de usuario o el email ya existen',
      }).then(function() {
        window.location.reload();
    } );
     }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
