import wait from "../utils/wait";
import cerrarSesion from "../services/logout";
import Swal from 'sweetalert2'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ENDPOINT = `${API_BASE_URL}`;
const jwtLocal = window.sessionStorage.getItem("jwt")


class AuthApi {
  /* LOGIN */
  async login({ email, password }) {
    await wait(500);

    return new Promise(async (resolve, reject) => {
      try {
        /* console.log(`${ENDPOINT}`) */
        return await fetch(`${ENDPOINT}/users-login/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        })
          .then((response) => response.json())
          .then((result) => resolve(result))
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: "error",
              title: "Algo salió mal!",
              text: "Error en el servidor",
            });
          });
      } catch (err) {
        console.log("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  /* REGISTER */

  /* async register({ username, name, last_name, email, password }) {
    await wait(1000);

    return new Promise(async (resolve, reject) => {
      try {
        return await fetch(`${ENDPOINT}/users-register/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, name, last_name, email, password }),
        }).then(response => response.text())
          .then((result) =>{
            const mensaje = (result.replace("[", " ").replace("]", " ")).replace(/['"]+/g, '')
          return(
          Swal.fire({
            icon: mensaje.includes("ya existe") ? 'error' : 'success',
            text: mensaje.includes("ya existe") ? mensaje : 'Usuario creado correctamente, por favor verifique su casilla de mail para poder verificar su cuenta y activarla'
            }))}
          ).then(window.location='/')
          .catch(Swal.fire({
            icon: 'error',
            title: 'Algo salió mal!',
            text: 'Error en el servidor',
          }))
      } catch (err) {
        alert(err);
        reject(new Error("Internal server error"));
      }
    });
      
  } */

  async register({ username, email, name, last_name, password }) {
    await wait(1000);

    return new Promise(async (resolve, reject) => {
      try {


return await fetch(`${ENDPOINT}/users-register/`, {
  method: 'POST',
  headers: {"Content-Type": "application/json"},
  body: JSON.stringify({ username, email, name, last_name, password }),
  redirect: 'follow'
})
  .then(response => response.text())
  .then((result) =>{
    const mensaje = (result.replace("[", " ").replace("{", " ").replace("message", " ").replace(":", " ").replace("}", " ").replace("]", " ")).replace(/['"]+/g, '')
    const res = result
    console.log(mensaje)
  return(
  Swal.fire({
    icon: mensaje.includes("existe") ? 'error' : 'success',
    title: mensaje.includes("existe") ? 'Error' : 'Éxito',
    text: mensaje.includes("existe") ? mensaje : 'Usuario creado correctamente, por favor verifique su casilla de mail para poder verificar su cuenta y activarla'
    }).then(()=>{
      if(mensaje.includes("correctamente")){
        window.location = "/"
      }
    })
    )}
  )
  .catch(error => console.log('error', error));
    } catch (err) {
      alert(err);
      reject(new Error("Internal server error"));
    }
  });
    
}

  /* ME */

  async me(userId, jwt) {
    return await fetch(`${ENDPOINT}/users/users/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + (jwt || jwtLocal),
      },
    })
      .then((response) => response.json())
      .then((result) => {

        if (result.detail === "El token dado no es valido para ningun tipo de token" ) {
          cerrarSesion();
        }
        else {
          console.log(result)
        }
        console.log(result);

        const user = {
          image: window.sessionStorage.setItem("image", result.image),
          email: window.sessionStorage.setItem("email", result.email),
          iqan: window.sessionStorage.setItem("iqan", result.iqan),
          username: window.sessionStorage.setItem("username", result.username),
          name: window.sessionStorage.setItem("name", result.name),
          last_name: window.sessionStorage.setItem("last_name", result.last_name),
          is_staff: window.sessionStorage.setItem("is_staff", result.is_staff),
          approved: window.sessionStorage.setItem("approved", result.approved),
        };
        return {
          id: result.id,
          image: result.image,
          user: result.username,
          name: result.name,
          last_name: result.last_name,
        };
      })
      .catch((error) => console.log("error", error));
  }
}

export const authApi = new AuthApi();
