import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Swal from 'sweetalert2';




const username = window.sessionStorage.getItem('username');

const GuestGuard = ({ children }) => {

  useEffect(() => {
    if (username==='undefined') {
      Swal.fire({
        title: 'Debe iniciar sesión',
        text: 'Para acceder a esta sección debe iniciar sesión',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'success',
        confirmButtonText: 'Ok',
      }).then(() => {
        localStorage.clear()
        }).then(
        () => {
          window.location.reload();
        }
      );
    }
    else{
      console.log('sigue logueado')
    }
  }, []);

  const { isAuthenticated, logout } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  if (username === 'undefined'){
    logout();
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
