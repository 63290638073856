import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import ArrowRightIcon from '../../../icons/ArrowRight';
import PencilAltIcon from '../../../icons/PencilAlt';
import SearchIcon from '../../../icons/Search';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import cerrarSesion from '../../../services/logout';

const statusOptions = [
  {
    label: 'Todo',
    value: 'all'
  },
  {
    label: 'Recibida',
    value: 'paid'
  },
  {
    label: 'Pendiente',
    value: 'pending'
  },
  {
    label: 'Cancelada',
    value: 'canceled'
  }
];

const sortOptions = [
  {
    label: 'Mas reciente',
    value: 'createdAt|desc'
  },
  {
    label: 'Mas antiguo',
    value: 'createdAt|asc'
  }
];

const getStatusLabel = (invoiceStatus) => {
  const map = {
    canceled: {
      color: 'error',
      text: 'Cancelada'
    },
    paid: {
      color: 'success',
      text: 'Recibida'
    },
    pending: {
      color: 'warning',
      text: 'Pendiente'
    }
  };

  const { text, color } = map[invoiceStatus];

  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

const applyFilters = (invoices, query, filters) => invoices
  .filter((invoice) => {
    let matches = true;

    if (query) {
      const properties = ['name', 'email'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (invoice.customer[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filters.status && invoice.status !== filters.status) {
      matches = false;
    }

    return matches;
  });

const applyPagination = (invoices, page, limit) => invoices
  .slice(page * limit, page * limit + limit);

const InvoiceListTable = (props) => {

  
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const ENDPOINT = `${API_BASE_URL}`;
const jwt = window.sessionStorage.getItem("jwt")
var myHeaders = new Headers()
myHeaders.append("Authorization", "Bearer " + jwt);
myHeaders.append("Content-Type", "application/json");

const [ users, setUsers ] = useState([])

    const fetchApi = async()=>{
    var raw = JSON.stringify();
    const response = await fetch(`${ENDPOINT}/tokens-movimientos/`, {
    method:"GET",
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  })
  const responseJSON = await response.json()

  
  setUsers(responseJSON)
}

var movimientos = users;
  if (movimientos.detail === "El token dado no es valido para ningun tipo de token" ) {
    window.location.href = "/"
    cerrarSesion();
  }
  else {
    console.log(movimientos)
  }

useEffect(() => {
  fetchApi()
},[])


const labelColors = {
  enviados: 'success',
  recibidos: 'warning',
  solicitados: 'info'
};

  
  const { invoices, ...other } = props;
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    status: null
  });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleStatusChange = (event) => {
    let value = null;

    if (event.target.value !== 'all') {
      value = event.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllInvoices = (event) => {
    setSelectedInvoices(event.target.checked
      ? invoices.map((invoice) => invoice.id)
      : []);
  };

  const handleSelectOneInvoice = (event, invoiceId) => {
    if (!selectedInvoices.includes(invoiceId)) {
      setSelectedInvoices((prevSelected) => [...prevSelected, invoiceId]);
    } else {
      setSelectedInvoices((prevSelected) => prevSelected.filter((id) => id !== invoiceId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const filteredInvoices = applyFilters(invoices, query, filters);
  const paginatedInvoices = applyPagination(filteredInvoices, page, limit);
  const enableBulkActions = selectedInvoices.length > 0;
  const selectedSomeInvoices = selectedInvoices.length > 0
    && selectedInvoices.length < invoices.length;
  const selectedAllInvoices = selectedInvoices.length === invoices.length;

  return (
    
    <Card {...other}>
      {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
         */}
      
        {/* <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Buscar movimiento por usuario"
            value={query}
            variant="outlined"
          />
        </Box> */}
        {/* <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 240
          }}
        >
          <TextField
            fullWidth
            label="Filtrar"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 240
          }}
        >
          <TextField
            fullWidth
            label="Estado"
            name="status"
            onChange={handleStatusChange}
            select
            SelectProps={{ native: true }}
            value={filters.status || 'all'}
            variant="outlined"
          >
            {statusOptions.map((statusOption) => (
              <option
                key={statusOption.value}
                value={statusOption.value}
              >
                {statusOption.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box> */}
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllInvoices}
              color="primary"
              indeterminate={selectedSomeInvoices}
              onChange={handleSelectAllInvoices}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Delete
            </Button>
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllInvoices}
                    color="primary"
                    indeterminate={selectedSomeInvoices}
                    onChange={handleSelectAllInvoices}
                  />
                </TableCell> */}
                <TableCell>
                  Nombre de Usuario
                </TableCell>
                <TableCell>
                  Monto
                </TableCell>
                <TableCell>
                  Fecha
                </TableCell>
                <TableCell>
                  Tipo de movimiento
                </TableCell>{/* 
                <TableCell align="right">
                  Acciones
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {(movimientos[0] !== undefined) 
              ? movimientos[0].map((invoice, i) => {
                const isInvoiceSelected = selectedInvoices.includes(invoice.id);
                const date = invoice[3].split('T')[0]
            console.log(date);
                return (
                  <TableRow
                    hover
                    key={i}
                    selected={isInvoiceSelected}
                  >
                    {/* <TableCell 
                      padding="checkbox">
                      <Checkbox
                        checked={isInvoiceSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneInvoice(event, invoice.id)}
                        value={isInvoiceSelected}
                      />
                    </TableCell> */}
                    <TableCell>
                      {invoice[0]}{" "}{invoice[1]}
                    </TableCell>
                    <TableCell>
                      {invoice[2]} Interpuntos
                    </TableCell>
                    <TableCell>
                      {date}
                    </TableCell>
                    <TableCell>
                      <Label color={labelColors.complete}>
                      enviados
                      </Label>
                    </TableCell>{/* 
                    <TableCell 
                      align="right">
                      <IconButton
                        component={RouterLink}
                        to="#"
                      >
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        component={RouterLink}
                        to="/dashboard/invoices/1"
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              }
              )
              
              : ''}
            </TableBody>
            <TableBody>
              {(movimientos[1] !== undefined) 
              ? movimientos[1].map((invoice, i) => {
                const isInvoiceSelected = selectedInvoices.includes(invoice.id);
                const date = invoice[3].split('T')[0]
            console.log(date);
                return (
                  <TableRow
                    hover
                    key={i}
                    selected={isInvoiceSelected}
                  >
                    {/* <TableCell 
                      padding="checkbox">
                      <Checkbox
                        checked={isInvoiceSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneInvoice(event, invoice.id)}
                        value={isInvoiceSelected}
                      />
                    </TableCell> */}
                    <TableCell>
                      {invoice[0]} {" "}{invoice[1]}
                    </TableCell>
                    <TableCell>
                      {invoice[2]} Interpuntos
                    </TableCell>
                    <TableCell>
                      {date}
                    </TableCell>
                    <TableCell>
                    <Label color={labelColors.recibidos}>
                      recibidos
                      </Label>
                    </TableCell>{/* 
                    <TableCell 
                      align="right">
                      <IconButton
                        component={RouterLink}
                        to="#"
                      >
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        component={RouterLink}
                        to="/dashboard/invoices/1"
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              }
              )
              
              : ''}
            </TableBody>
            <TableBody>
              {(movimientos[2] !== undefined) 
              ? movimientos[2].map((invoice, i) => {
                const isInvoiceSelected = selectedInvoices.includes(invoice.id);
                const date = invoice[3].split('T')[0]
            console.log(date);
                return (
                  <TableRow
                    hover
                    key={i}
                    selected={isInvoiceSelected}
                  >
                    {/* <TableCell 
                      padding="checkbox">
                      <Checkbox
                        checked={isInvoiceSelected}
                        color="primary"
                        onChange={(event) => handleSelectOneInvoice(event, invoice.id)}
                        value={isInvoiceSelected}
                      />
                    </TableCell> */}
                    <TableCell>
                      {invoice[0]} {" "}{invoice[1]}
                    </TableCell>
                    <TableCell>
                      {invoice[2]} Interpuntos
                    </TableCell>
                    <TableCell>
                      {date}
                    </TableCell>
                    <TableCell>
                    <Label color={labelColors.solicitados}>
                      solicitados
                      </Label>
                    </TableCell>{/* 
                    <TableCell 
                      align="right">
                      <IconButton
                        component={RouterLink}
                        to="#"
                      >
                        <PencilAltIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        component={RouterLink}
                        to="/dashboard/invoices/1"
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                );
              }
              )
              
              : ''}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      {/* <TablePagination
        component="div"
        count={filteredInvoices.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
};

InvoiceListTable.propTypes = {
  invoices: PropTypes.array.isRequired
};

export default InvoiceListTable;
