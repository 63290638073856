import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Help = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
  <g id="master_icon-left_medium" data-name="master/icon-left/medium" transform="translate(2 -3)">
    <g id="solid_chat-alt" data-name="solid/chat-alt" transform="translate(-4)">
      <path id="Vector" d="M16,2v8a2,2,0,0,1-2,2H9L4,16V12H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H14a2,2,0,0,1,2,2ZM5,5H3V7H5ZM7,5H9V7H7Zm6,0H11V7h2Z" transform="translate(2 3)" fill="rgba(#919EAB)"/>
    </g>
  </g>
</svg>

  , 'Help'
);

export default Help;