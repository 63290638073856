import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Divider, Drawer, Typography, LinearProgress } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Help from "../../icons/Help";
import UserIcon from "../../icons/User";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import Historial from "../dashboard/invoice/InvoiceListTable";

/* ICONS GENEREATE */
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import FillSquare from "../../icons/FillSquare";
import FolderOpenIcon from "../../icons/FolderOpen";
import GraphicCircle from "../../icons/GraphicCircle";

/* ICONS MATERIAL */
import SendIcon from '@mui/icons-material/Send';
import PeopleIcon from '@mui/icons-material/People';
import StarsIcon from '@mui/icons-material/Stars';
import ReceiptIcon from "@material-ui/icons/Receipt";
import EmailIcon from '@mui/icons-material/Email';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import AssessmentIcon from '@mui/icons-material/Assessment';

const admin = [
  {
    title: "ADMIN",
    items: [
      {
        title: "Informes",
        path: "/dashboard/admin-report",
        icon: <AssessmentIcon  fontSize="small" />,
      },{
        title: "Carga de usuarios",
        path: "/dashboard/massive-users",
        icon: <DriveFileMoveIcon  fontSize="small" />,
      },
    ]
  }
]

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const ENDPOINT = `${API_BASE_URL}`;

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Inicio",
        path: "/dashboard",
        icon: <FillSquare fontSize="small" />,
      },
      {
        title: "QR Persona",
        path: "/dashboard/qr-code",
        icon: <QrCodeIcon fontSize="small" />,
      },
      {
        title: "Enviar",
        path: "/dashboard/send",
        icon: <SendIcon fontSize="small" />,
      },
      {
        title: "Solicitar",
        path: "/dashboard/request",
        icon: <SendIcon sx={{transform: 'rotate(180deg)'}} fontSize="small" />,
      },
      {
        title: "Agenda",
        path: "/dashboard/contacts",
        icon: <PeopleIcon fontSize="small" />,
      },
      {
        title: "Movimientos",
        path: "/dashboard/invoices",
        icon: <GraphicCircle fontSize="small" />,
      },/* 
      {
        title: "Ayuda",
        path: "#",
        icon: <Help fontSize="small" />,
      }, */
    ],
  },
  {
    title: "Usuario",
    items: [
      /* {
        title: "Puntos",
        icon: <StarsIcon fontSize="small" />,
        path: "#",
      },
      {
        title: "Cuenta",
        path: "#",
        icon: <EmailIcon fontSize="small" />,
      }, */
      {
        title: "Perfil",
        path: "/dashboard/account",
        icon: <UserIcon fontSize="small" />,
      }/* ,
      {
        title: "Configuración",
        path: "#",
        icon: <SettingsIcon fontSize="small" />,
      },
      {
        title: "Crear Negocio",
        path: "#",
        icon: <ShoppingCartIcon fontSize="small" />,
      }, */
    ],
  },
];
const platformIcons = {
  Resistencia: "/static/icons/resistencia.svg",
};

const DashboardSidebar = (props) => {
  const user = {
    email: sessionStorage.getItem("email"),
    username: sessionStorage.getItem("username"),
    name: sessionStorage.getItem("name"),
    last_name: sessionStorage.getItem("last_name"),
    is_staff: sessionStorage.getItem("is_staff"),
    image: sessionStorage.getItem("image"),
  };
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 4000);
  }, []);



  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    //eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          <img alt="Auth platform" src={platformIcons.Resistencia} />
        </Box>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            {loading
            ? (
            <>
            <RouterLink to="/dashboard/account">
              {user.name === "undefined" && user.last_name === "undefined" ? (
                ""
              ) : (
                <Avatar
                  src={`${ENDPOINT}${user.image}`}
                  sx={{
                    cursor: "pointer",
                    height: 48,
                    width: 48,
                  }}
                />
              )}
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name === "undefined" && user.last_name === "undefined"
                  ? ""
                  : user.name + " " + user.last_name}
              </Typography>
              <Typography color="textSecondary" variant="body2"></Typography>
            </Box>
            </>)
            : (<Typography sx={{
              textAlign: "center"}} variant="h6">
                    <LinearProgress color="secondary" />
                  </Typography>)}
          </Box>
        </Box>
        <Divider />


        { user.is_staff === "true" ?
        <Box
        sx={{ p: 2 }}>
          {admin.map((admins) => (
            <NavSection
              key={admins.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...admins}
            />
          ))}
        </Box>
        : ""}



        <Divider />
        { user.is_staff === "false" ?
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        : ""}
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
