import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar, Typography, Avatar, LinearProgress } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import ContactsPopover from "./ContactsPopover";
import ContentSearch from "./ContentSearch";
import LanguagePopover from "./LanguagePopover";
import Logo from "../Logo";
import NotificationsPopover from "./NotificationsPopover";
import wait from '../../utils/wait'

const platformIcons = {
  Qaixa: "/static/icons/interpuntos.png",
};


const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  


  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 4000);
  }, []);

  const user = {
    email: sessionStorage.getItem("email"),
    username: sessionStorage.getItem("username"),
    name: sessionStorage.getItem("name"),
    last_name: sessionStorage.getItem("last_name"),
  };

  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          minHeight: 64,
        }}
      >
      <IconButton
      color="inherit"
      onClick={onSidebarMobileOpen}
      sx={{
        marginRight: 2,
        display: {
          lg: 'none'
        }
      }}
    >
      <MenuIcon fontSize="large" />
    </IconButton>
        <Box
        sx={{
          display: {
          xs: 'none',
          sm: 'flex'
        }}}>
          <RouterLink
            to="/"
          >
            
          </RouterLink>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <LanguagePopover /> */}
        <Box sx={{ ml: 1 }}>{/* <ContentSearch /> */}</Box>
        <Box sx={{ ml: 1 }}>{/* <ContactsPopover /> */}</Box>
        

        <Box
            sx={{
              display: "flex",
            }}
          >
            {loading
            ?(
            <>
              {user.name === "null" && user.last_name === "null" ? (
                <AccountPopover />
              ) : (
                <AccountPopover />
              )}
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name === "undefined" && user.last_name === "undefined"
                  ? ""
                  : user.name + " " + user.last_name}
              </Typography>
              <Typography color="textPrimary" variant="subtitle2">
                {user.username === "undefined"
                  ? ""
                  : user.username}
              </Typography>
            </Box>
            </>)
            : (<Typography sx={{
              textAlign: "center"}} variant="h6">
                    <LinearProgress color="primary" />
                  </Typography>)}
          </Box>
          
        <Box sx={{ ml: 1 }}><NotificationsPopover /></Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
